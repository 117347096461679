<!-- 

<span *ngIf="giftExpiry"
  >FREE TRIAL TERMS AND CONDITIONS: <br />Valid only for new subscribers for a period of {{ trialDaysText }} ({{
    trialDays
  }}) days upon account creation. Fee of US${{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ giftExpiry | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.</span
>
<span
  *ngIf="
    (!giftExpiry && planType == 'trial' && isChange != 'true' && unsubscribe != 'true') || (isEmpty && !giftExpiry)
  "
>
  <span *ngIf="!parentData?.metadata.registeredFrom">FREE TRIAL </span>TERMS AND CONDITIONS: <br />
  <span *ngIf="!parentData?.metadata.vendor && !parentData?.metadata.registeredFrom"
    >Valid only for new subscribers for a period of {{ trialDaysText }} ({{ trialDays }}) days upon account creation.
  </span>
  Fee of US${{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>
<span *ngIf="!giftExpiry && isChange == 'true' && unsubscribe != 'true'">
  TERMS AND CONDITIONS: <br />Fee of US${{ plan?.planMonthly }} will be deducted from your credit card monthly<span
    *ngIf="planType == 'trial' && unsubscribe != 'true'"
  >
    from {{ momentNext | date: 'd LLL  yyyy' }}</span
  >.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.</span
> -->

<!-- test -->
<!-- plan change & trial -->
<span *ngIf="templateName == 'changeTrial'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- plan change & paid -->
<span *ngIf="templateName == 'changePaid'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & normal -->
<span
  *ngIf="templateName == 'emptyNormal' || templateName == 'giftCardClaim' || templateName == 'giftCardClaimWithAuth'"
  class="terms-condition"
>
  FREE TRIAL TERMS AND CONDITIONS: <br />
  Valid only for new subscribers for a period of {{ trialDaysText }} ({{ trialDays }}) days upon account creation. Fee
  of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & vendor -->
<span *ngIf="templateName == 'emptyVendor'" class="terms-condition">
  TRIAL TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & from start -->
<span *ngIf="templateName == 'emptyFromStart'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & promo -->
<span *ngIf="templateName == 'emptyPromo'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- gift card  -->
<span *ngIf="templateName == 'emptyGiftcard'" class="terms-condition">
  FREE TRIAL TERMS AND CONDITIONS: <br />
  Valid only for new subscribers for a period of {{ trialDaysText }} ({{ trialDays }}) days upon account creation. Fee
  of {{currency | uppercase}} {{ plan?.planMonthly }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>
