<app-gift-card
  *ngIf="giftFlag"
  [loading]="loading"
  [invalidCode]="invalidCode"
  class="cover-all"
  (giftCancel)="giftCancel($event)"
  (giftContinue)="giftContinue($event)"
></app-gift-card>

<div class="container" *ngIf="userType != 'promo' || (userType == 'promo' && currentPlanObj.planId)">
  <app-header-bar
    [parentData]="parentData"
    [currentPlanObj]="currentPlanObj"
    [unsubscribe]="unsubscribe"
    [templateName]="templateName"
  ></app-header-bar>

  <app-loader
    [spinColor]="'#b1b1b3'"
    [height]="'433px'"
    [width]="'100%'"
    *ngIf="!mobileView && tableloading"
  ></app-loader>
  <app-loader
    [spinColor]="'#b1b1b3'"
    [height]="'728px'"
    [width]="'100%'"
    *ngIf="mobileView && tableloading"
  ></app-loader>

  <div class="mt-40" class="table-container" *ngIf="!tableloading">
    <app-plan-table
      [planList]="planList"
      [currentPlanObj]="currentPlanObj"
      [mobilePlan]="mobilePlan"
      [remainTrialDay]="remainTrialDay > 0 ? remainTrialDay : trialDays"
      [parentData]="parentData"
      [typeFromParams]="typeFromParams"
      [selectedPlan]="selectedPlan"
      [selectedPlanId]="selectedPlanId"
      (planSelected)="selectedPlanData($event)"
    ></app-plan-table>
  </div>

  <div class="footer">
    <app-terms-condition
    [currency]="planList[0].currency"
      [selectedPrice]="selectedPrice"
      [momentNext]="momentNext"
      [trialDays]="trialDays"
      [trialDaysText]="trialDaysText"
      [templateName]="templateName"
    ></app-terms-condition>
    <div class="d-flex justify-content-between align-item-center mt-40 gift-wrap">
      <div>
        <span
          class="gift-card cursor"
          (click)="addGift()"
          [ngClass]="{ 'd-none': currentPlanObj.planId || parentData?.metadata.vendor }"
          >Claim a gift card instead?</span
        >
      </div>
      <button
        type="submit"
        class="cursor d-flex justify-content-center align-item-center"
        (click)="continue()"
        [ngClass]="{ continueButton: selectedPlanId, 'btn-disable': !selectedPlanId }"
      >
        <app-text [class]="'btn-text-style2'" [loading]="!selectedPlanId">Continue </app-text>
      </button>
    </div>
  </div>
</div>

<div class="container" *ngIf="userType == 'promo' && !currentPlanObj.planId">
  <app-header *ngIf="parentData" (openProfile)="openProfile($event)" [parentData]="parentData"></app-header>

  <div (click)="backPage()" class="back-div" *ngIf="currentPlanObj.planId">
    <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
  </div>

  <div class="choice" style="margin-bottom: 8px">Choose your plan</div>

  <app-image
    [src]="planInfo?.metadata?.landscapeURL"
    *ngIf="!mobileView && planInfo?.metadata?.landscapeURL"
    [class]="imageToggle"
    (imageIsLoaded)="imageLoad()"
  ></app-image>
  <app-image
    [src]="planInfo?.metadata?.portraitURL"
    *ngIf="mobileView && planInfo?.metadata?.portraitURL"
    [class]="imageToggle"
    (imageIsLoaded)="imageLoad()"
  ></app-image>
  <div class="d-flex justify-content-center align-items-center">
    <div>
      <app-loader
        [spinColor]="'#b1b1b3'"
        [height]="'433px'"
        [width]="'100%'"
        *ngIf="!mobileView && !planInfo?.metadata?.landscapeURL"
        [class]="loaderToggle"
      ></app-loader>
      <app-loader
        [spinColor]="'#b1b1b3'"
        [height]="'728px'"
        [width]="'100%'"
        *ngIf="mobileView && !planInfo?.metadata?.portraitURL"
        [class]="loaderToggle"
      ></app-loader>
    </div>
    <app-text *ngIf="invalidPromoMessage" style="position: absolute" [class]="'light-body-l text-r100'">{{
      invalidPromoMessage
    }}</app-text>
  </div>

  <div class="footer">
    <app-terms-condition
      [selectedPrice]="selectedPrice"
      [momentNext]="momentNext"
      [trialDays]="trialDays"
      [trialDaysText]="trialDaysText"
      [templateName]="'emptyPromo'"
    ></app-terms-condition>

    <div class="d-flex justify-content-between align-item-center mt-40 gift-wrap">
      <div>
        <span
          class="gift-card cursor"
          (click)="addGift()"
          [ngClass]="{ 'd-none': currentPlanObj.planId || parentData?.metadata.vendor }"
          >Claim a gift card instead?</span
        >
      </div>
      <button
        type="submit"
        class="cursor d-flex justify-content-center align-item-center"
        (click)="continue()"
        [class]="'continueButton'"
      >
        <app-text [class]="'btn-text-style2'" [loading]="subLoading">Continue </app-text>
      </button>
    </div>
  </div>
</div>

<app-query-params-ls></app-query-params-ls>
