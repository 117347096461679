/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-condition.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./terms-condition.component";
import * as i4 from "./terms-condition.store";
var styles_TermsConditionComponent = [i0.styles];
var RenderType_TermsConditionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionComponent, data: {} });
export { RenderType_TermsConditionComponent as RenderType_TermsConditionComponent };
function View_TermsConditionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "terms-condition"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TERMS AND CONDITIONS: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Fee of ", " ", " will be deducted from your credit card monthly from ", ". "])), i1.ɵppd(4, 1), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "https://www.stemwerkz.org/end-user-agreement"], ["style", "color: #007d49; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here"])), (_l()(), i1.ɵted(-1, null, [" for more details on the payment terms and subscription cancellation.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currency)); var currVal_1 = _co.selectedPrice; var currVal_2 = i1.ɵunv(_v, 3, 2, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), _co.momentNext, "d LLL yyyy")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TermsConditionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "terms-condition"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TERMS AND CONDITIONS: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Fee of ", " ", " will be deducted from your credit card monthly. "])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "https://www.stemwerkz.org/end-user-agreement"], ["style", "color: #007d49; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here"])), (_l()(), i1.ɵted(-1, null, [" for more details on the payment terms and subscription cancellation.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currency)); var currVal_1 = _co.selectedPrice; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_TermsConditionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "terms-condition"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" FREE TRIAL TERMS AND CONDITIONS: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Valid only for new subscribers for a period of ", " (", ") days upon account creation. Fee of ", " ", " will be deducted from your credit card monthly from ", ". "])), i1.ɵppd(4, 1), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "https://www.stemwerkz.org/end-user-agreement"], ["style", "color: #007d49; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here"])), (_l()(), i1.ɵted(-1, null, [" for more details on the payment terms and subscription cancellation.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trialDaysText; var currVal_1 = _co.trialDays; var currVal_2 = i1.ɵunv(_v, 3, 2, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currency)); var currVal_3 = _co.selectedPrice; var currVal_4 = i1.ɵunv(_v, 3, 4, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), _co.momentNext, "d LLL yyyy")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_TermsConditionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "terms-condition"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TRIAL TERMS AND CONDITIONS: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Fee of ", " ", " will be deducted from your credit card monthly from ", ". "])), i1.ɵppd(4, 1), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "https://www.stemwerkz.org/end-user-agreement"], ["style", "color: #007d49; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here"])), (_l()(), i1.ɵted(-1, null, [" for more details on the payment terms and subscription cancellation.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currency)); var currVal_1 = _co.selectedPrice; var currVal_2 = i1.ɵunv(_v, 3, 2, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), _co.momentNext, "d LLL yyyy")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TermsConditionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "terms-condition"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TERMS AND CONDITIONS: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Fee of ", " ", " will be deducted from your credit card monthly. "])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "https://www.stemwerkz.org/end-user-agreement"], ["style", "color: #007d49; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here"])), (_l()(), i1.ɵted(-1, null, [" for more details on the payment terms and subscription cancellation.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currency)); var currVal_1 = _co.selectedPrice; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_TermsConditionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "terms-condition"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TERMS AND CONDITIONS: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Fee of ", " ", " will be deducted from your credit card monthly from ", ". "])), i1.ɵppd(4, 1), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "https://www.stemwerkz.org/end-user-agreement"], ["style", "color: #007d49; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here"])), (_l()(), i1.ɵted(-1, null, [" for more details on the payment terms and subscription cancellation.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currency)); var currVal_1 = _co.selectedPrice; var currVal_2 = i1.ɵunv(_v, 3, 2, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), _co.momentNext, "d LLL yyyy")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TermsConditionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsConditionComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsConditionComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsConditionComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsConditionComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsConditionComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsConditionComponent_6)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.templateName == "changeTrial"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.templateName == "changePaid"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.templateName == "emptyNormal"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.templateName == "emptyVendor"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.templateName == "emptyFromStart"); _ck(_v, 11, 0, currVal_4); var currVal_5 = (_co.templateName == "emptyPromo"); _ck(_v, 13, 0, currVal_5); }, null); }
export function View_TermsConditionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-condition", [], null, null, null, View_TermsConditionComponent_0, RenderType_TermsConditionComponent)), i1.ɵdid(1, 114688, null, 0, i3.TermsConditionComponent, [i4.TermsConditionStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsConditionComponentNgFactory = i1.ɵccf("app-terms-condition", i3.TermsConditionComponent, View_TermsConditionComponent_Host_0, { trialDaysText: "trialDaysText", trialDays: "trialDays", selectedPrice: "selectedPrice", momentNext: "momentNext", templateName: "templateName", currency: "currency" }, {}, []);
export { TermsConditionComponentNgFactory as TermsConditionComponentNgFactory };
