<app-discount-popup
  *ngIf="discountFlag"
  class="cover-all"
  (discountCancel)="discountCancel($event)"
  (discountContinue)="discountContinue($event)"
  [discountCode]="discountCode"
></app-discount-popup>

<app-gift-card
  *ngIf="giftFlag"
  [invalidCode]="invalidCode"
  class="cover-all"
  (giftCancel)="giftCancel($event)"
  (giftContinue)="giftContinue($event)"
></app-gift-card>

<app-redeem-loading *ngIf="redeeming"></app-redeem-loading>

<div class="container" *ngIf="!isPromo">
  <app-header-bar
    [parentData]="parentData"
    [currentPlanName]="currentPlanName"
    [isChange]="isChange"
    [unsubscribe]="unsubscribe"
    [giftCodeAdded]="giftCodeAdded"
    (outputBack)="backPage()"
  ></app-header-bar>

  <div *ngIf="mobileView" class="plan-title">
    <app-text>STEMWerkz {{ plan?.planName ? plan?.planName : 'Plan Name' }}</app-text>
  </div>

  <div class="d-flex plan-detail" [ngClass]="{ 'justify-content-between': !mobileView, 'flex-column': mobileView }">
    <div class="left-div">
      <app-loader *ngIf="loader" [spinColor]="'#b1b1b3'" [height]="'300px'" [width]="'100%'"></app-loader>
      <div *ngIf="!loader" [ngClass]="{ 'mt-40': !mobileView, 'mt-16': mobileView }">
        <app-plan-detail
          [plan]="plan"
          [loader]="loader"
          [isChange]="isChange"
          [unsubscribe]="unsubscribe"
          [parentData]="parentData"
          [remainTrialDay]="remainTrialDay"
          [trialDays]="trialDays"
          [downgrade]="downgrade"
          [giftCodeAdded]="giftCodeAdded"
        ></app-plan-detail>
      </div>
      <div class="paragraph" *ngIf="!loader">
        <app-terms-condition
          [plan]="plan"
          [currency]="plan.currency"
          [momentNext]="giftExpiry ? giftExpiry : momentNext"
          [trialDays]="trialDays"
          [trialDaysText]="trialDaysText"
          [templateName]="templateName"
        ></app-terms-condition>
      </div>
    </div>

    <div *ngIf="!loader" id="right-div" [ngClass]="{ 'h-auto': giftCodeAdded }">
      <app-info-card
        [giftCodeAdded]="giftCodeAdded"
        [loading]="loading"
        [correctDiscountCode]="correctDiscountCode"
        [isAuth]="isAuth"
        [isEmpty]="isEmpty"
        [unsubscribe]="unsubscribe"
        [plan]="plan"
        [amount]="amount"
        [giftExpiry]="giftExpiry"
        [templateName]="templateName"
        [discountText]="discountText"
        [discountCode]="discountCode"
        (btnClicked)="infoCardClicked($event)"
      ></app-info-card>
    </div>

    <div *ngIf="loader" id="right-div" [ngClass]="{ 'h-auto': giftCodeAdded, 'h-455': !giftCodeAdded }">
      <div class="plan"><app-loader [spinColor]="'#b1b1b3'" [height]="'16px'" [width]="'50%'"></app-loader></div>
      <div class="latter">
        <div class="inform">
          <app-loader [spinColor]="'#b1b1b3'" [height]="'16px'" [width]="'50%'"></app-loader>
        </div>
        <app-loader [spinColor]="'#b1b1b3'" [height]="'48px'" [width]="'100%'"></app-loader>
        <app-loader [spinColor]="'#b1b1b3'" [height]="'48px'" [width]="'100%'"></app-loader>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container" *ngIf="isPromo">
  <app-header-bar
    [parentData]="parentData"
    [currentPlanName]="currentPlanName"
    [isChange]="isChange"
    [unsubscribe]="unsubscribe"
    [giftCodeAdded]="giftCodeAdded"
    (outputBack)="backPage()"
  ></app-header-bar>
  <div *ngIf="mobileView" class="plan-title">
    <app-text>STEMWerkz {{ plan?.planName ? plan?.planName : 'Plan Name' }}</app-text>
  </div>
  <div class="d-flex plan-detail" [ngClass]="{ 'justify-content-between': !mobileView, 'flex-column': mobileView }">
    <div class="left-div">
      <app-loader *ngIf="loader" [spinColor]="'#b1b1b3'" [height]="'300px'" [width]="'100%'"></app-loader>
      <div *ngIf="!loader" [ngClass]="{ 'mt-40': !mobileView, 'mt-16': mobileView }">
        <table class="table">
          <tr *ngIf="!mobileView">
            <th></th>
            <th
              id="value"
              class="plan-type solo"
              style="
                background: url(assets/images/selected-bg.svg);
                background-repeat: no-repeat;
                background-position-x: center;
              "
            >
              {{ plan?.planName ? plan?.planName : 'Plan Name' }}
            </th>
            <app-loader *ngIf="loader" [spinColor]="'#b1b1b3'" [height]="'10px'" [width]="'100px'"></app-loader>
          </tr>
          <tr>
            <td><span class="td-title">Monthly price</span></td>
            <td id="s-price" class="plan-content" [ngClass]="{ 'line-through': giftCodeAdded && plan?.planMonthly }">
              {{ plan?.planMonthly ? plan?.planMonthly : '---' }} {{ plan?.currency | uppercase }}
            </td>
          </tr>
          <tr>
            <td class="td-title">Child accounts</td>
            <td id="s-price" class="plan-content">{{ plan?.playerSlot ? plan?.playerSlot : '---' }}</td>
          </tr>
          <tr>
            <td class="td-title">1,000+ engaging STEM lessons</td>
            <td id="s-content" class="plan-content">
              <app-image [src]="selectedTick"></app-image>
            </td>
          </tr>
          <tr *ngIf="isChange != 'true' || unsubscribe == 'true'">
            <td
              class="td-title"
              [ngClass]="{ 'line-through': parentData?.metadata.vendor || (remainTrialDay && remainTrialDay <= 0) }"
            >
              {{ remainTrialDay && remainTrialDay > 0 ? remainTrialDay : trialDays }} days free trial
            </td>
            <ng-container *ngIf="parentData?.metadata.vendor">
              <td id="s-content" class="plan-content">
                <app-text style="color: #a40e68">-</app-text>
              </td>
            </ng-container>
          </tr>
        </table>
      </div>
      <div class="paragraph" *ngIf="!loader">
        <app-terms-condition
          [plan]="plan"
          [momentNext]="momentNext"
          [trialDays]="trialDays"
          [trialDaysText]="trialDaysText"
          [templateName]="'emptyPromo'"
        ></app-terms-condition>
      </div>
    </div>

    <div *ngIf="!loader" id="right-div" [ngClass]="{ 'h-auto': giftCodeAdded }">
      <div class="plan">Your promotion information</div>
      <div class="latter">
        <div class="inform">
          <div class="pink-title">
            You are about to redeem your “STEMWerkz {{ plan?.planName ? plan?.planName : '---' }} {{ amount }}
            {{ amount > 1 ? 'months' : 'month' }}” promotion!
          </div>
          <div class="text-c300" style="margin-top: 8px">
            To ensure a seamless experience on STEMWerkz, please set up your payment details. Deduction of
            {{ plan?.planMonthly ? plan?.planMonthly : '---' }} {{ plan?.currency | uppercase }}/month will only be made
            after the gift card claim period, from
            <span style="font-weight: bold" class="text-c300" *ngIf="!giftExpiry">---</span
            ><span style="font-weight: bold" class="text-c300" *ngIf="giftExpiry">{{
              giftExpiry | date: 'd LLL  yyyy'
            }}</span
            >. Don’t worry, if for some reason you don’t love STEMWerkz anymore, you may cancel at any time.
          </div>
        </div>
        <app-button
          [class]="'btn-orange'"
          [loading]="loading"
          (click)="setupPayment()"
          *ngIf="
            (isAuth && isEmpty && giftCodeAdded && unsubscribe != 'true') ||
            (isAuth && !giftCodeAdded && unsubscribe != 'true')
          "
        >
          <app-text [class]="'btn-text-style2'" [loading]="loading" *ngIf="!giftCodeAdded && !correctDiscountCode">
            {{ loading ? 'Proceeding... ' : 'Proceed' }}
          </app-text>
        </app-button>

        <div
          *ngIf="!giftCodeAdded"
          class="d-flex justify-content-center"
          style="width: 100%; margin-top: 24px; margin-bottom: 34px; cursor: pointer"
          (click)="cancelPayment()"
        >
          <app-text
            [ngClass]="{ 'btn-disable': loading, 'btn-cancel2': !loading }"
            [class]="'btn-text-cancel2'"
            [loading]="loading"
            >{{ unsubscribe == 'true' ? 'Back' : 'Cancel' }}</app-text
          >
        </div>
      </div>
    </div>
    <div *ngIf="loader" id="right-div" [ngClass]="{ 'h-auto': giftCodeAdded, 'h-455': !giftCodeAdded }">
      <div class="plan"><app-loader [spinColor]="'#b1b1b3'" [height]="'16px'" [width]="'50%'"></app-loader></div>
      <div class="latter">
        <div class="inform">
          <app-loader [spinColor]="'#b1b1b3'" [height]="'16px'" [width]="'50%'"></app-loader>
        </div>
        <app-loader [spinColor]="'#b1b1b3'" [height]="'48px'" [width]="'100%'"></app-loader>
        <app-loader [spinColor]="'#b1b1b3'" [height]="'48px'" [width]="'100%'"></app-loader>
      </div>
    </div>
  </div>
</div> -->
<app-query-params-ls></app-query-params-ls>
