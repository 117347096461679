<!-- plan change & trial -->
<span *ngIf="templateName == 'changeTrial'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ selectedPrice }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- plan change & paid -->
<span *ngIf="templateName == 'changePaid'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ selectedPrice }} will be deducted from your credit card monthly.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & normal -->
<span *ngIf="templateName == 'emptyNormal'" class="terms-condition">
  FREE TRIAL TERMS AND CONDITIONS: <br />
  Valid only for new subscribers for a period of {{ trialDaysText }} ({{ trialDays }}) days upon account creation. Fee
  of {{currency | uppercase}} {{ selectedPrice }} will be deducted from your credit card monthly from {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & vendor -->
<span *ngIf="templateName == 'emptyVendor'" class="terms-condition">
  TRIAL TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ selectedPrice }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & from start -->
<span *ngIf="templateName == 'emptyFromStart'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ selectedPrice }} will be deducted from your credit card monthly.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>

<!-- empty & promo -->
<span *ngIf="templateName == 'emptyPromo'" class="terms-condition">
  TERMS AND CONDITIONS: <br />
  Fee of {{currency | uppercase}} {{ selectedPrice }} will be deducted from your credit card monthly from
  {{ momentNext | date: 'd LLL  yyyy' }}.
  <a target="_blank" style="color: #007d49; text-decoration: none" href="https://www.stemwerkz.org/end-user-agreement"
    >Click here</a
  >
  for more details on the payment terms and subscription cancellation.
</span>
