export const environment = {
  production: false,
  apiurl: 'https://sv-api-staging.stemwerkz.org',
  apikey: 'bc77612ecd1a2e7f6ace383b4cee7848',
  secretkey: 'bc776',
  orgName: 'steamwerkz-staging',
  initState: 'signup',
  environmentName: 'staging',
  dashboardurl: 'https://student-staging.stemwerkz.org',
  channelurl: 'https://channel-staging.stemwerkz.org/',
  valleyurl: 'https://sv-staging.stemwerkz.org/',
  academyurl: 'https://channel-staging.stemwerkz.org/',
  questurl: 'https://quest.stemwerkz.org/',
  claimURL: 'https://account-staging.stemwerkz.org/claim-giftcard',
  parenturl: 'https://parent-staging.stemwerkz.org/',
  // valley: "https://sv-staging.stemwerkz.org",
  // channel: "https://channel-staging.stemwerkz.org/login",
  // academy: "https://channel-staging.stemwerkz.org/liveclasses",
  howToSignInLink: 'https://www.stemwerkz.org/howto',
  guidedTourLink: 'https://www.stemwerkz.org/howto',
  stemwerkzLink: 'https://www.stemwerkz.org/',
  nodmaApi: 'https://app.pagewerkz.com',
  titleForgotPassword: 'Forgot your password? | STEMWerkz',
  titleResetPassword: 'Reset your password | STEMWerkz',
  titleStore: 'Store | STEMWerkz',
  fbPixel: false
};
